import React from "react";
import "./navbar.css";
import pwLogo from "../../assets/pwlogo.png";
import { useNavigate } from "react-router-dom";

export const Navbar = (props) => {
  const navigate = useNavigate();

  function stackClick() {
    props.clickHandler();
  }

  return (
    <div className="navbar">
      <div className="navbarGrid">
        <h1 className="menuStack" style={{ 'fontFamily': 'Material Icons' }} onClick={() => {stackClick()}}>menu</h1>
        <img src={pwLogo} alt="PW Logo" className="pwlogo" />
        <h1 className="whiteText" style={{ 'width': '10em' }} >PW Vidyapeeth</h1>
        <div className="maxWidthFlexContainer">
          <button className="materialButtonSmall" onClick={() => {
            localStorage.removeItem('token');
            navigate('/')
          }} >
            logout
          </button>
        </div>
      </div>
    </div>
  );
};

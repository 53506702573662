import React from "react";
import "./layout.css";
import { useState } from "react";
import { Navbar } from "../navbar/navbar.jsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { errorToast } from "../../config/toast-config";
import { Config } from "../../config/config";

export default function Layout(props) {
  const [sidebar, setSidebar] = useState('-400px');
  const navigate = useNavigate();
  const location = useLocation();
  const [userScopes, setUserScopes] = useState([]);

  function toggleSidebar() {
    sidebar === '-400px' ? setSidebar('0px') : setSidebar('-400px')
  }

  const menuItems = [
    {
      label: 'All Content',
      material: 'video_library',
      required_scope: 'SECTION_VIDEOS',
      route: '/content'
    },
    {
      label: 'Upload Content',
      material: 'file_upload',
      required_scope: 'SECTION_UPLOAD',
      route: '/upload'
    },
    {
      label: 'Create Thumbnail',
      material: 'photo_library',
      required_scope: 'SECTION_THUMBNAIL',
      route: '/thumbnail'
    },
    {
      label: 'Center Management',
      material: 'hub',
      required_scope: 'SECTION_CENTERS',
      route: '/center'
    },
    {
      label: 'Manage Accounts',
      material: 'manage_accounts',
      required_scope: 'SECTION_ACCOUNTS',
      route: '/manage'
    }
  ]

  useState(() => {
    axios.get(`${Config.scheme}://${Config.host}/v1/user/self`, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
      .then((res) => {
        setUserScopes(res.data.data[0].scopes)
      })
      .catch((err) => {
        if (err.response.status === 401) { navigate('/login') }
        else {
          toast.error('An error occured! please refresh the page', errorToast)
        }
      })
  })

  return (
    <div className="main">
      <ToastContainer position="bottom-right" theme="dark" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover={false} />
      <Navbar clickHandler={toggleSidebar} />
      <div className="body" onClick={() => { setSidebar('-400px') }}>
        <div className="sidebar" style={{ 'left': sidebar }} >
          <div className="sidebarGrid" >
            {
              menuItems.map((e) => {
                if (location.pathname === e.route) {
                  return (
                    <div className="activeSidebarGridItem" onClick={() => { navigate(e.route) }}  >
                      <h1 className="sidebarMaterialIcon">{e.material}</h1>
                      <h3 className="activeSidebarLabel" >{e.label}</h3>
                    </div>
                  )
                }
                else {
                  return (
                    <div className="sidebarGridItem" onClick={() => { navigate(e.route) }}  >
                      <h1 className="sidebarMaterialIcon">{e.material}</h1>
                      <h3 className="sidebarLabel" >{e.label}</h3>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
        <div className="bodyGrid">
          <div className="verticalMenu">
            <div className="menuGrid">
              {
                menuItems.map((e) => {
                  if (location.pathname === e.route) {
                    return (
                      <h1 className="menuMaterialIconActive" onClick={() => { navigate(e.route) }} >{e.material}</h1>
                    )
                  }
                  else {
                    return (
                      <h1 className="menuMaterialIcon" onClick={() => { navigate(e.route) }}>{e.material}</h1>
                    )
                  }
                })
              }
            </div>
          </div>
          <div className="childContainer">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

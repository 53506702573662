import './App.css';
import { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AllContent } from './pages/allContent/allContent';
import { LoginPage } from './pages/login/login';
import { UploadPage } from './pages/upload/upload';
import { ManageAccounts } from './pages/manage/manageAccounts';
import { Thumbnail } from './pages/thumbnail/thumbnail';
import { CenterManagement } from './pages/centers/center';

class App extends Component {
  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
      <Router>
        <Routes>
          <Route exact path='/' element={<LoginPage />} />
          <Route exact path='/login' element={<LoginPage />} />
          <Route exact path='/content' element={<AllContent />} />
          <Route exact path='/upload' element={<UploadPage />} />
          <Route exact path='/manage' element={<ManageAccounts />} />
          <Route exact path='/thumbnail' element={<Thumbnail />} />
          <Route exact path='/center' element={<CenterManagement />} />
        </Routes>
      </Router>
    );
  }

}

export default App;

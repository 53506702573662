import React, { useRef, useEffect } from "react";
import "./upload.css";
import Layout from "../../components/layout/layout";
import loadingGif from '../../assets/upload.gif';
import { useState } from "react";
import FormData from 'form-data';
import axios from 'axios';
import { toast } from "react-toastify";
import { errorToast } from "../../config/toast-config";
import { useNavigate } from "react-router-dom";
import { Config } from "../../config/config";
import SelectSearch from 'react-select-search';

/**
 * The options array should contain objects.
 * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
 */
const options = [
    {name: 'Swedish', value: 'sv'},
    {name: 'English', value: 'en'},
];

export const UploadPage = (props) => {
    const fileRef = useRef();
    const homeworkRef = useRef();
    const navigate = useNavigate();
    const [centerData, setCenterData] = useState([]);
    const [batchData, setBatchData] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [tags, setTags] = useState([]);
    const [adminBatchId, setAdminBatchId] = useState('');
    const [uploadButton, setUploadButton] = useState('uploadButton');
    const [videoSelected, setVideoSelected] = useState(false);
    const [spinner, setSpinner] = useState('spinnerWrapper');
    const [videoPreview, setVideoPreview] = useState('invisible');
    const [videoBlob, setVideoBlob] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [loaded, setLoaded] = useState(0);
    const [totalToBeLoaded, setTotalToBeLoaded] = useState(100);
    const [videoUploadProgressLabel, setVideoUploadProgressLabel] = useState('invisible');
    const [faculties, setFaculties] = useState([]);
    const [subTopic, setSubTopic] = useState('');
    const [driveFaculty, setDriveFaculty] = useState(false);

    useState(() => {
        axios.get(`${Config.scheme}://${Config.host}/v1/config/center/all`, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
            .then((res) => {
                setSpinner('invisibleWrapper')
                setCenterData(res.data.data);
            })
            .catch((error) => {
                if (error.response.status === 401) { navigate('/login') }
                else {
                    toast.error('An error occured! please refresh the page', errorToast)
                }
            })
    }, [])


    const [subject, setSubject] = useState({
        id: 'null',
        name: 'null'
    })
    const [batch, setBatch] = useState({
        id: 'null',
        name: 'null'
    })
    const [center, setCenter] = useState({
        id: 'null',
        name: 'null'
    })
    const [lectureNumber, setLectureNumber] = useState('');
    const [lecture, setLecture] = useState('');
    const [tag, setTag] = useState([]);
    const [faculty, setFaculty] = useState('alakhpandey');
    const [homework, setHomework] = useState(null);
    const [homeworkName, setHomeworkName] = useState('Please select a file');
    let change = false;
    useEffect(()=>{
        change = true
    },[lecture])

    function lectureInput (){

    }

    function fileInputHandler(e) {
        if (e.target.files[0]) {
            setVideoSelected(true);
            setUploadButton('invisible');
            setVideoPreview('videoPreview');
            const videoUrl = URL.createObjectURL(e.target.files[0])
            console.log(videoUrl);
            setVideoBlob(videoUrl);
            setSelectedFile(e.target.files[0]);
        }
    }

    function notesInputHandler(e) {
        if (homework === null) {
            if (e.target.files[0]) {
                setHomeworkName(String(e.target.files[0].name + '...').slice(0, 18));
                setHomework(e.target.files[0]);
            }
        }
        else {
            setHomeworkName('Please select a file');
            setHomework(null);
        }
    }

    function uploadFile() {
        if (selectedFile == null) {
            toast.error('No file selected!', errorToast);
        }
        else if (subject.id === 'null' || center.id === 'null' || subject.id === 'null' || lecture === '' || tag.length < 1) {
            toast.error('Please fill all the details!', errorToast);
        }
        else {
            setVideoUploadProgressLabel('whiteStaticHeader');
            setSpinner('spinnerWrapper')
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('homework', homework);
            formData.append('subjectId', subject.id)
            formData.append('subjectName', subject.name)
            formData.append('batchId', batch.id)
            formData.append('batchName', batch.name)
            formData.append('lectureName', `${lecture} - ${lectureNumber}`)
            formData.append('faculty', faculty)
            formData.append('centerId', center.id)
            formData.append('centerName', center.name)
            formData.append('subTopic', subTopic)
            formData.append('tags', JSON.stringify(tag))
            if(faculty == 'alakhpandey') formData.append('driveThumbnail', false);
            else formData.append('driveThumbnail', driveFaculty)
            axios.post(`${Config.scheme}://${Config.host}/v1/video/add`, formData, {
                headers: {
                    Authorization: localStorage.getItem('token'),
                    ...formData.getHeaders
                },
                onUploadProgress: (progressE) => {
                    setLoaded(progressE.loaded)
                    setTotalToBeLoaded(progressE.total)
                }
            })
                .then((res) => {
                    navigate('/content')
                    setSpinner('invisible')
                    setVideoUploadProgressLabel('invisible')
                })
                .catch((err) => {
                    setSpinner('invisible')
                    toast.error('An unexpected error occured!', errorToast)
                    setVideoUploadProgressLabel('invisible');
                })
        }
    }

    function subjectChange(values) {
        if (values.indexOf('null') === -1) {
            setSpinner('spinnerWrapper')

            const data = subject;
            data.id = values[0];
            data.name = values[1];
            setSubject(data);
            axios.get(`${Config.scheme}://${Config.host}/v1/admin/batch/${adminBatchId}/subject/${values[0]}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
                .then((res) => {
                    setTags(res.data.data);
                    setSpinner('invisibleWrapper')
                })
                .catch((error) => {
                    if (error.response.status === 401) { navigate('/login') }
                    else {
                        toast.error('An unexpected error occured!', errorToast)
                        setSpinner('invisibleWrapper')
                    }
                })
        }
    }

    function batchChange(values) {
        if (values.indexOf('null') === -1) {
            setSpinner('spinnerWrapper')
            const ppId = values[0]
            const data = {};
            data.id = ppId;
            data.name = values[1];
            setAdminBatchId(values[0]);
            setBatch(data);
            axios.get(`${Config.scheme}://${Config.host}/v1/admin/batch/${values[0]}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
                .then((res) => {
                    setSubjects(res.data.data);
                    setSpinner('invisibleWrapper')
                })
                .catch((error) => {
                    if (error.response.status === 401) { navigate('/login') }
                    else {
                        toast.error('An unexpected error occured!', errorToast)
                        setSpinner('invisibleWrapper')
                    }
                })
        }
    }

    function facultyChange(e) {
        setFaculty(e.target.value);
    }

    function centerChange(values) {
        if (values.indexOf('null') === -1) {
            setSpinner('spinnerWrapper')
            const data = center;
            data.id = values[0];
            data.name = values[1];
            setCenter(data);
            const oneCenterData = centerData.filter((center) => center._id === values[0])
            axios.get(`${Config.scheme}://${Config.host}/v1/admin/category/${oneCenterData[0].categoryId}`, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            })
                .then((res) => {
                    setBatchData(res.data.data);
                    if(!oneCenterData[0].driveId) {
                        setSpinner('invisibleWrapper')
                        setDriveFaculty(false)
                        setFaculties(oneCenterData[0].faculties.sort())
                    }
                    else {
                        setDriveFaculty(true)
                        axios.get(`${Config.scheme}://${Config.host}/v1/config/faculty/list/${oneCenterData[0].driveId}`, {
                            headers: {
                                Authorization: localStorage.getItem('token')
                            }
                        })
                        .then((res) => {
                            setSpinner('invisibleWrapper')
                            setFaculties(res.data.data)
                        })
                        .catch((error) => {
                            if (error.response.status === 401) { navigate('/login') }
                            else {
                                toast.error('An unexpected error occured!', errorToast)
                                setSpinner('invisibleWrapper')
                            }
                        })
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) { navigate('/login') }
                    else {
                        toast.error('An unexpected error occured!', errorToast)
                        setSpinner('invisibleWrapper')
                    }
                })
        }
    }

    function tagChange(values) {
        if (values.indexOf('null') === -1) {
            const data = [];
            const testString = String(values[1]).replaceAll(' ', '').slice(0,6).toLowerCase();
            console.log(testString)
            if(testString.includes('ch') && testString.includes(':')) {
                console.log(true)
                const index = String(values[1]).indexOf(':');
                setLecture(String(values[1]).slice(index + 1, Infinity).trim());
            }
            else {
                setLecture(values[1]);
            }
            data.push(String(values[0]))
            setTag(data);
        }
    }

    function removeFile() {
        setSelectedFile(null);
        setVideoPreview('invisible');
        setUploadButton('uploadButton')
    }

    function removeHomework() {
        setSelectedFile(null);
    }

    return (
        <>
            <div className={spinner}>
                <div className="spinner" />
                <h1 className={videoUploadProgressLabel} style={{ 'marginTop': '10em' }} >Total Uploaded - {((loaded / totalToBeLoaded) * 100).toFixed(2)}%</h1>
            </div>
            <Layout>
                <div className="uploadMain">
                    <div className="uploadInterface">
                        <div className="uploadDetailsContainer" >
                            <h1 className="whiteText">Upload Video</h1>
                            <div className="closeFlexHeaderContent">
                                     <input  defaultValue={
                                                lecture.length > 0 ? lecture + " : " : "" 
                                              } 
                                        type="text" className="titleInput" 
                                        placeholder="Tag Name" 
                                        onChange={(e) =>{setLecture(e.target.value)}}
                                        style={{ 'width': '70%' }}
                                        disabled={lecture == ''}
                                    />
                                    <input 
                                        value={lectureNumber}
                                        type="text" className="titleInput" 
                                        placeholder="Lecture Number" 
                                        onChange={(e) => { setLectureNumber(e.target.value) }}
                                     />
                            </div>
                            <div className="selectLabelGrid">
                            <h3 className="dropDownLabelUpload">Subtopic -</h3>
                            <input 
                                        type="text" className="subTitleInput" 
                                        placeholder="Subtopic" 
                                        onChange={(e) => { setSubTopic(e.target.value) }}
                            />
                            </div>
                            <div className="selectLabelGrid">
                                <h3 className="dropDownLabelUpload">Select Center -</h3>
                                <select className="customDropDown" style={{ 'width': '100%' }} onChange={(e) => { centerChange(String(e.target.value).split('~')) }}>
                                    <option value="null~null">Select a center</option>
                                    {
                                        centerData.map((center) => {
                                            return (
                                                <option value={`${center._id}~${center.name}`}>{center.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="selectLabelGrid">
                                <h3 className="dropDownLabelUpload">Select Batch -</h3>
                                {/* <SelectSearch search options={options} name="batch" placeholder="Select a batch" /> */}
                                <select className="customDropDown" style={{ 'width': '100%' }} onChange={(e) => { batchChange(String(e.target.value).split('~')) }}>
                                    <option value="null~null">Select a batch</option>
                                    {
                                        batchData.map((batch) => {
                                            return (
                                                <option value={`${batch.id}~${batch.name}`}>{batch.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="selectLabelGrid">
                                <h3 className="dropDownLabelUpload">Select Subject -</h3>
                                <select className="customDropDown" style={{ 'width': '100%' }} onChange={(e) => { subjectChange(String(e.target.value).split('~')) }}>
                                    <option value="null~null">Select a subject</option>
                                    {
                                        subjects.map((subject) => {
                                            return (
                                                <option value={`${subject.id}~${subject.subject.name}~${subject.subject.id}`}>{subject.subject.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="selectLabelGrid">
                                <h3 className="dropDownLabelUpload">Select Tag -</h3>
                                <select className="customDropDown" style={{ 'width': '100%' }} onChange={(e) => { tagChange(String(e.target.value).split('~')) }}>
                                    <option value="null~null">Select a tag</option>
                                    {
                                        tags.map((tag) => {
                                            return (
                                                
                                                <option value={`${tag.id}~${tag.name}`}>{tag.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="selectLabelGrid">
                                <h3 className="dropDownLabelUpload">Select Faculty -</h3>
                                <select className="customDropDown" style={{ 'width': '100%', display: driveFaculty ? 'none' : '' }} onChange={(e) => { facultyChange(e) }}>
                                    <option value="alakhpandey" >Select a faculty</option>
                                    {
                                        faculties.map((faculty) => {
                                            if(driveFaculty) return
                                            return (
                                                <option value={`${String(faculty).replaceAll(' ', '').toLowerCase()}`}>{faculty}</option>
                                            )
                                        })
                                    }
                                </select>
                                <select className="customDropDown" style={{ 'width': '100%', display: driveFaculty ? '' : 'none' }} onChange={(e) => { facultyChange(e) }}>
                                    <option value="alakhpandey" >Select a faculty</option>
                                    {
                                        faculties.map((faculty) => {
                                            return (
                                                <option value={JSON.stringify(faculty)}>{String(faculty.name).replaceAll('.png', '').replaceAll('.jpg', '').replaceAll('.jpeg', '')}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <input type="file" accept="video/*,.mkv,.m4v" className="invisible" onChange={(e) => { fileInputHandler(e) }} ref={fileRef} />
                        <div className={uploadButton} onClick={() => { fileRef.current.click() }} >
                            <img src={loadingGif} alt="loadingGif" className="uploadGif" />
                            <h2 className="dropDownLabelUpload" >Click here to upload</h2>
                        </div>
                        <div className={videoPreview} >
                            <video className="videoPlayer" src={videoBlob} controls />
                            <div className="buttonGridUploadInterface" style={{ 'width': '80%' }} >
                                <button className="redButton" onClick={() => { removeFile() }} >REMOVE</button>
                                <button className="purpleButton" onClick={() => { uploadFile() }} >UPLOAD</button>
                            </div>
                            <div className="buttonGridUploadInterface" style={{ 'width': '80%' }} >
                                <button className="purpleButton" onClick={() => {
                                    console.log(homework);
                                    console.log(homeworkName)
                                    if(homework == null) {
                                    homeworkRef.current.click()
                                    }
                                    else {
                                        notesInputHandler()
                                        removeHomework()
                                    }
                                    }} >{homework == null ? "Attach Notes" : "Remove Notes"}</button>
                                <p className="whiteStaticText" >{homeworkName}</p>
                                <input accept="application/pdf" ref={homeworkRef} type="file" className="invisible" onChange={(e) => { notesInputHandler(e) }} />
                            </div>
                        </div>
                    </div>
        
                </div>
            </Layout>
        </>
    );
};

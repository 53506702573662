import React, { useState, useEffect } from "react";
import './center.css';
import Layout from "../../components/layout/layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorToast, warningToast, successToast } from "../../config/toast-config";
import { Config } from "../../config/config";
import { Batch } from "../../components/batch/batch";


export const CenterManagement = (props) => {
    const navigate = useNavigate();
    const [batchAdd, setBatchAdd] = useState(false)
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [spinner, setSpinner] = useState('spinnerWrapper');

    useEffect(() => {
        axios.get(`${Config.scheme}://${Config.host}/v1/config/center/all`, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
            .then((res) => {
                setData(res.data.data);
                setAllData(res.data.data);
                setSpinner('invisible');
            })
            .catch((error) => {
                if (error.response.status === 401) { navigate('/login') }
                else {
                    toast.error('An error occured! please refresh the page', errorToast)
                }
            })
    }, [navigate])

    function centerSearch(e) {
        if(e.target.value === '') {
            const rawData = [...allData]
            setData(rawData)
        }
        else {
        const rawData = [...allData]
        const filteredData = rawData.filter(center => String(center.name).toLowerCase().replaceAll(' ', '').includes(String(e.target.value).toLowerCase().replaceAll(' ', '')))
        setData(filteredData);
        }
    }

    function closeBatchAdd(){
        setBatchAdd(false)
    }


    return (
        <>
            <div className={spinner} >
                <div className="spinner" />
            </div>

            <Layout>
            <div>{batchAdd ? <Batch close={closeBatchAdd} /> : null}</div>

                <div className="manageProfiles">

                    <div className="flexHeaderContent">
                        <h1 className="whiteHeader">Manage Centers</h1>
                        <div className="closeFlexHeaderContent">
                            <input type="text" className="searchBarAllContent" placeholder="Search User..." onChange={(e) => { centerSearch(e) }} />
                            <button className="purpleButton" onClick={() => { }} >Add Center</button>
                            <select className="customDropDown" >
                                <option>My Centers</option>
                                <option>All Centers</option>
                            </select>
                        </div>
                    </div>

                    <div className="profilesGridContainer" >
                        <div className="centersGrid">
                            <div className="centersCardsHeader">
                                <h1 className="whiteStaticHeader" >S No.</h1>
                                <h1 className="whiteStaticHeader" >Name</h1>
                                <h1 className="whiteStaticHeader" >Batches</h1>
                                <h1 className="whiteStaticHeader" >Actions</h1>
                            </div>

                            {
                                data.map((center, key) => {
                                    return (
                                        <div className="centerCard">
                                            <h1 className="whiteStaticText" >{key + 1}</h1>
                                            <h1 className="whiteStaticText" >{center.name}</h1>
                                            <select className="userScopes" >
                                                {center.batches.map((batch) => {
                                                    return (<option className="whiteStaticText" >{batch.name}</option>)
                                                })}
                                            </select>
                                            <div className="closeFlexHeaderContent">
                                                <button className="materialButtonSmall" onClick={() => setBatchAdd(true)}>edit</button>
                                                <button className="materialButtonSmall" onClick={() => { toast.warn('Coming soon!', warningToast) }} >delete</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </Layout>
        </>
    )
}


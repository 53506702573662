import { useEffect, useState, useRef, createFactory } from "react";
import Fuse from 'fuse.js'
import "./allcontent.css";
import Layout from "../../components/layout/layout";
import { toast } from "react-toastify";
import { errorToast, warningToast } from "../../config/toast-config.js";
import { VideoCard } from "../../components/videoCard/videoCard.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { successToast } from "../../config/toast-config.js";
import { Config } from "../../config/config";
import FormData from 'form-data';
import Toggle from 'react-toggle'
import 'react-toggle/style.css';

export const AllContent = (props) => {
  const inputRef = useRef();

  const nullData = {
    youtubeId: '',
    thumbnailUrl: '',
    lecture: {
      name: '',
      subTopic: ''
    },
    batch: {
      name: '',
      id:''
    },
    faculty: '',
    subject: {
      name: '',
      id:''
    },
    centerName: '',
    published: true,
    homework: {
      name: 'NA'
    }
  }

  let limit = 50, type = 'self';

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryType, setCategoryType] = useState('self');
  const [videoModal, setVideoModal] = useState('-50em');
  const [modalView, setModalView] = useState(['0', 'none']);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState(nullData);
  const [spinner, setSpinner] = useState('spinnerWrapper');
  const [editTitleDiv, setEditTitleDiv] = useState('editTitleInputDiv');
  const [editTitleInputDiv, setEditTitleInputDiv] = useState('invisible');
  const [title, setTitle] = useState('');
  const [scrollLoading, setScrollLoading] = useState(false);
  const [totalKnownCount, setTotalKnownCount] = useState(1);
  const [recentSchedules, setRecentSchedules] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [scheduleId, setScheduleId] = useState("");
  const [existingDiv, setExistingDiv] = useState("invisible");
  const [publishButtonNew, setPublishButtonNew] = useState("invisible");

  useEffect(() => {
    axios.get(`${Config.scheme}://${Config.host}/v1/video/${categoryType}`, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
      .then((res) => {
        setData(res.data.data)
        setTotalKnownCount(res.data.pagination.total);
        setSpinner('invisible')
      })
      .catch((error) => {
        if (error.response.status === 401) { navigate('/login') }
        else {
          toast.error('An error occured! please refresh the page', errorToast)
        }
      })
  }, [navigate])

  const options = {
    includeScore: true,
    keys: [
      'lecture.name',
      'subject.name'
    ]
  };

  function refresh() {
    setSpinner('spinnerWrapper')
    axios.get(`${Config.scheme}://${Config.host}/v1/video/${categoryType}`, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
      .then((res) => {
        setData(res.data.data)
        setTotalKnownCount(res.data.pagination.total);
        setSpinner('invisible')
      })
      .catch((error) => {
        if (error.response.status === 401) { navigate('/login') }
        else {
          toast.error('An error occured! please refresh the page', errorToast)
        }
      })
  }

  function modalCall(data) {
    if (modalView[0] === '0') {
      if (!data.youtubeId == '') {
        setModalData(data)
        setModalView(['1', 'grid']);
        setVideoModal('0em');
        setTitle(data.lecture.name);
        axios
        .get(
          `${Config.scheme}://${Config.host}/v1/admin/batch/${data.batch.id}/subject/${data.subject.id}/recent-schedule`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        )

        .then((res) => {
          setRecentSchedules(res.data.data);
          console.log(recentSchedules);
        });
      }
      else {
        toast.warn('Video still processing, please check back later!', warningToast)
      }
    }
    else {
      setModalData(nullData)
      setModalView(['0', 'none']);
      setVideoModal('-50em');
      setEditTitleDiv('editTitleDiv');
      setEditTitleInputDiv('invisible');
      setRecentSchedules([])
      setSchedules([])
    }
  }

  function handleEditTitle() {
    setEditTitleDiv('invisible')
    setEditTitleInputDiv('editTitleInputDiv')
  }

  function editThumbnail(e) {
    if (e.target.files[0]) {
      setSpinner('spinnerWrapper')
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      axios.put(`${Config.scheme}://${Config.host}/v1/video/edit/thumbnail/${modalData._id}`, formData, {
        headers: {
          Authorization: localStorage.getItem('token'),
          ...formData.getHeaders
        },
      })
        .then((res) => {
          setSpinner('invisible')
          toast.success('Thumbnail will be changed soon!', successToast)
        })
        .catch((error) => {
          if (error.response.status === 401) { navigate('/login') }
          else {
            toast.error('An error occured! please refresh the page', errorToast)
          }
        })
    }
  }

  function handleVideoSave() {
    if (!(String(modalData.lecture.name).trim() === String(title).trim())) {
      setSpinner('spinnerWrapper')
      axios.put(`${Config.scheme}://${Config.host}/v1/video/edit/title/${modalData._id}`, {
        title: title
      }, {
        headers: { Authorization: localStorage.getItem('token') }
      })
        .then((res) => {
          setSpinner('invisible')
          toast.success('Title will be changed soon!', successToast)
        })
        .catch((error) => {
          if (error.response.status === 401) { navigate('/login') }
          else {
            toast.error('An error occured! please refresh the page', errorToast)
          }
        })
    }
  }

  function changeCategory(e) {
    setSpinner('spinnerWrapper')
    setCategoryType(e.target.value);
    axios.get(`${Config.scheme}://${Config.host}/v1/video/${e.target.value}`, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
      .then((res) => {
        setData(res.data.data)
        setTotalKnownCount(res.data.pagination.total);
        setSpinner('invisible')
      })
      .catch((error) => {
        if (error.response.status === 401) { navigate('/login') }
        else {
          toast.error('An error occured! please refresh the page', errorToast)
        }
      })
  }

  function scrollToBottomDetector(e) {
    if (!scrollLoading) {
      const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
      if (bottom) {
        console.log('Reached the bottom, loading further data if available')
        if (totalKnownCount > data.length) {
          setScrollLoading(true)
          axios.get(`${Config.scheme}://${Config.host}/v1/video/${categoryType}?skip=${data.length}&limit=${limit}`, {
            headers: {
              Authorization: localStorage.getItem('token')
            }
          })
            .then((res) => {
              const oldData = [...data]
              const combinedArray = oldData.concat(res.data.data);
              console.log('newData - ', combinedArray)
              setData(combinedArray)
              setTotalKnownCount(res.data.pagination.total)
              setSpinner('invisible')
              setScrollLoading(false)
            })
            .catch((error) => {
              if (error.response.status === 401) { navigate('/login') }
              else {
                toast.error('An error occured! please refresh the page', errorToast)
              }
            })
        }
      }
    }
  }

  const getSchedules = (e) => {
    setSchedules(
      recentSchedules[document.getElementById("dayDropdown").selectedIndex - 1]
        .schedules
    );
  };

  useEffect(() => {
    console.log(schedules);
  }, [schedules]);

  function getScheduleDetails(e) {
    setScheduleId(e.target.value);
  }

  useEffect(() => {
    console.log(scheduleId);
  }, [scheduleId]);

  function enablePublish(e) {
    if (e.target.value === "Existing"){ 
    setExistingDiv("existingScheduleDiv");
    setPublishButtonNew("invisible");
  }
    else if (e.target.value === "New")  {
      setPublishButtonNew("purpleButton");
    setExistingDiv("invisible")
    } 
    
  }

  function publishVideo() {
    setSpinner('spinnerWrapper')
    axios.get(`${Config.scheme}://${Config.host}/v1/admin/publish/${modalData._id}`, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
      .then(() => {
        setSpinner('invisible')
        toast.success('Video Published Successfully!', successToast)
      })
      .catch((error) => {
        setSpinner('invisible')
        if (error.response.status === 401) { navigate('/login') }
        else {
          toast.error('An error occured! please try again later', errorToast)
        }
      })
  }

  function publishVideoExisting() {
    setSpinner("spinnerWrapper");
    axios
      .get(
        `${Config.scheme}://${Config.host}/v1/admin/publish/${scheduleId}/${modalData._id}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then(() => {
        setSpinner("invisible");
        toast.success("Video Published Successfully!", successToast);
      })
      .catch((error) => {
        setSpinner("invisible");
        if (error.response.status === 401) {
          navigate("/login");
        } else {
          toast.error("An error occured! please try again later", errorToast);
        }
      });
  }

  function getFacultyName() {
    if(modalData.faculty == "alakhpandey") return modalData.faculty
    if(modalData.driveThumbnail) {
      const facultyData = JSON.parse(modalData.faculty)
      return facultyData.name
    }
    else {
      return modalData.faculty
    }
  }

  const fuse = new Fuse(data, options);
  const resultsArr = fuse.search(searchQuery)
  const contentArray = searchQuery ? resultsArr.map(result => result.item) : data;

  return (
    <>
      <div className={spinner}>
        <div className="spinner" />
      </div>

      <div className="modalView" style={{ 'scale': modalView[0], 'display': modalView[1] }}  >
        <div style={{ 'margin-bottom': videoModal }} className="modal" >
          <h1 className="whiteText">Video Details</h1>
          <div className="modalOptionsFlex">
            <div className="modalOptionsGrid">

              <div className="spreadFlexHeaderContent">
                <div className="editContentDivs">
                  <div className="editLabelDiv">
                    <h1 className="whiteStaticHeader">Title</h1>
                    <button className="editTitleButton" onClick={() => { handleEditTitle() }}>edit</button>
                  </div>
                  <div className={editTitleDiv}>
                    <h2 className="whiteStaticText">{modalData.lecture.name}</h2>
                  </div>
                  <div className={editTitleInputDiv}>
                    <textarea className="editTitleInput" onChange={(e) => { setTitle(e.target.value) }} defaultValue={modalData.lecture.name} />
                  </div>
                </div>


              </div>


              <div className="spreadFlexHeaderContent">
                <div className="editContentDivs">
                  <h1 className="whiteStaticHeader">Center</h1>
                  <h2 className="whiteStaticText">{modalData.centerName}</h2>
                </div>

                <div className="editContentDivs">
                  <h1 className="whiteStaticHeader">Batch</h1>
                  <h2 className="whiteStaticText">{modalData.batch.name}</h2>
                </div>

                <div className="editContentDivs">
                  <h1 className="whiteStaticHeader">Subject</h1>
                  <h2 className="whiteStaticText">{modalData.subject.name}</h2>
                </div>
              </div>

              <div className="spreadFlexHeaderContent">

                <div className="editContentDivs">
                  <h1 className="whiteStaticHeader">Faculty</h1>
                  <h2 className="whiteStaticText">{getFacultyName()}</h2>
                </div>

                <div className={modalData.homework.name == 'NA' ? "invisible" : "editContentDivs"}>
                  <h1 className="whiteStaticHeader">Homework</h1>
                  <h2 className="whiteStaticText">{modalData.homework.name}</h2>
                </div>

                {/* <div className="editContentDivs">
                  <div className="closeFlexHeaderContent">
                    <h1 className="whiteStaticHeader">Morning</h1>
                    <h1 className="whiteStaticHeader">Evening</h1>
                  </div>
                  <Toggle />
                </div> */}

              </div>

              <div className="closeFlexHeaderContent" >
                <button className="purpleButton" onClick={() => window.open(`https://youtu.be/${modalData.youtubeId}`, "_blank")} >Watch Video</button>
                <button className="purpleButton" onClick={() => {
                  navigator.clipboard.writeText(`https://youtube.com/embed/${modalData.youtubeId}`);
                  toast.success('Link copied to clipboard successfully!', successToast)
                }} >Copy Link</button>
                <button className="purpleButton" onClick={() => { handleVideoSave() }} disabled={String(modalData.lecture.name).trim() === String(title).trim()} >Save</button>
                <button className="purpleButton" onClick={() => { modalCall() }} >Close</button>
              </div>

              <div className="closeFlexHeaderContentSchedules">
              <select
                  className="customDropDown"
                  style={{ width: "100%" }}
                  onChange={enablePublish}
                >
                  <option value="null~null" style={{ "text-align": "center" }}>
                    Schedule Type
                  </option>
                  <option style={{ "text-align": "center" }}>Existing</option>
                  <option style={{ "text-align": "center" }}>New</option>
                </select>
                <div className={existingDiv}>
                  <select
                    className="customDropDown"
                    id="dayDropdown"
                    style={{ width: "100%" }}
                    onChange={getSchedules}
                  >
                    <option
                      value="null~null"
                      style={{ "text-align": "center" }}
                    >
                      Select Date
                    </option>
                    {recentSchedules.map((e) => {
                      return (
                        <option style={{ "text-align": "center" }}>
                          {e.day}/{e.month}/{e.year}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className="customDropDown"
                    style={{ width: "100%" }}
                    onChange={getScheduleDetails}
                  >
                    <option
                      value="null~null"
                      style={{ "text-align": "center" }}
                    >
                      Select Schedule
                    </option>
                    {schedules.map((e) => {
                      return (
                        <option
                          value={e._id}
                          style={{ "text-align": "center" }}
                        >
                          {e.topic}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className={
                      modalData.published ? 'redButton' : 'purpleButton'
                    }
                    onClick={() => {
                      publishVideoExisting();
                    }}
                  >
                    {
                    modalData.published ? 'Re-Publish' : 'Publish Existing'
                  }
                  </button>
                </div>
                <div className={publishButtonNew}>
                <button className={
                  modalData.published ? 'redButton' : 'purpleButton'
                }
                  onClick={() => { publishVideo() }} >
                  {
                    modalData.published ? 'Re-Publish' : 'Publish New'
                  }
                </button>
                </div>
                
                
              </div>
            </div>

            <div className="videoPreviewAndThumbnailContainer">
              <iframe className="videoIframe" src={`https://www.youtube.com/embed/${String(modalData.youtubeId)}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <div className="thumbnailWrapper" onClick={() => { inputRef.current.click() }} >
                <input type="file" accept=".png,.jpeg" className="invisible" ref={inputRef} onChange={(e) => { editThumbnail(e) }} />
                <h1 className="editThumbnailText" >Edit Thumbnail</h1>
                <img className="videoPreviewThumbnail" src={modalData.thumbnailUrl} alt='Thumbnail' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Layout>
        <div className="home">

          <div className="flexHeaderContent">
            <h1 className="whiteHeader">All Content</h1>
            <div className="closeFlexHeaderContent">
              <input type="text" className="searchBarAllContent" placeholder="Search Content..." onChange={(e) => { setSearchQuery(e.target.value) }} />
              <button className="purpleButton" onClick={() => { navigate('/upload') }} >UPLOAD VIDEO</button>
              <button className="materialButton" onClick={() => { refresh() }} >refresh</button>
              <select className="customDropDown" onChange={(e) => { changeCategory(e) }} >
                <option value="self" >My Videos</option>
                <option value="all" >All Videos</option>
              </select>
            </div>
          </div>

          <div className="videosGridWrapper" onScroll={(e) => { scrollToBottomDetector(e) }} >
            <div className="videosGrid"  >
              {
                contentArray.map((e) => {
                  return (
                    <VideoCard data={e} clickHandler={modalCall} />
                  )
                })
              }
            </div>
          </div>

        </div>
      </Layout>
    </>
  );
};





import React from "react";
import "./videoCard.css";

export const VideoCard = (props) => {
  const data = props.data

  return (
    <div
      className="videoCard"
      onClick={() => {
        props.clickHandler(data);
      }}
    >
      <img
        className="thumbnail"
        src={data.thumbnailUrl}
        alt="thumbnail"
      />
      <p className="textWrap">
       {data.lecture.name}
      </p>
      <h5 className="centerName">{data.centerName} - {data.subject.name}</h5>
    </div>
  );
};

import React from "react";
import './thumbnail.css';
import Layout from "../../components/layout/layout";

export const Thumbnail = (props) => {
    return (
    <div className='main'>
        <Layout>
            <iframe title="thumbnail" src="https://thumbnail.pwfb.xyz" className='maxIframe' ></iframe>
        </Layout>
    </div>
    )
}
import React from "react";
import './addMemberModal.css';
import { useState } from "react";
import { toast } from "react-toastify";
import { successToast, warningToast, errorToast } from "../../../config/toast-config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Config } from "../../../config/config";

export const AddMemberModal = (props) => {
    const navigate = useNavigate();

    const nullData = {
        _id: '',
        email: '',
        name: '',
        scopes: [],
        centerId: []
    }

    const defaultEdits = {
        name: false,
        email: false,
        scopes: false,
        center: false
    }

    const scopesArray = [
        "CREATE_USERS",
        "CREATE_CENTERS",
        "VIEW_CENTERS",
        "VIEW_USERS",
        "MANAGE_USERS",
        "DELETE_USERS",
        "ADD_VIDEOS",
        "GET_VIDEOS",
        "PUBLISH_VIDEOS",
        "EDIT_VIDEOS",
        "GET_BATCH",
        "CREATE_BATCH"
    ];

    const [editModalView, setEditModalView] = useState(['0', 'none']);
    const [editModalData, setEditModalData] = useState({});
    const [videoModal, setVideoModal] = useState('-50em');
    const [selectedScope, setSelectedScope] = useState([]);
    const [scopes, setScopes] = useState(scopesArray);
    const [centers, setCenters] = useState([]);
    const [selectedCenters, setSelectedCenters] = useState([]);
    const [memberName, setMemberName] = useState('');
    const [memberEmail, setMemberEmail] = useState('');
    const [edits, setEdits] = useState(defaultEdits)

    function nameEdit(e) {
        if (String(e.target.value).trim() === String(props.data.name).trim()) {
            const data = { ...edits };
            data.name = false;
            setEdits(data);
            setMemberName(e.target.value);
        }
        else {
            const data = { ...edits };
            data.name = true;
            setEdits(data);
            setMemberName(e.target.value);
        }
    }

    function emailEdit(e) {
        if (String(e.target.value).trim() === String(props.data.email).trim()) {
            const data = { ...edits };
            data.email = false;
            setEdits(data);
        }
        else {
            const data = { ...edits };
            data.email = true;
            setEdits(data);
            setMemberEmail(e.target.value);
        }
    }

    function scopeAdd(e) {
        if (e.target.value !== 'null') {
            const index = props.selectedScope.indexOf(e.target.value);
            if (index === -1) {
                const data = [...props.selectedScope];
                data.push(e.target.value);
                props.setSelectedScope(data)
                if (props.data.scopes.every(item => data.includes(item)) && data.every(item => props.data.scopes.includes(item))) {
                    const editData = { ...edits };
                    editData.scopes = false;
                    setEdits(editData)
                }
                else {
                    const editData = { ...edits };
                    editData.scopes = true;
                    console.log(editData)
                    setEdits(editData)
                }
            }
            else {
                toast.warn('Scope already selected!', warningToast)
            }
        }
    }

    function scopeDelete(scope) {
        const selectedScopeData = [...props.selectedScope];
        selectedScopeData.splice(selectedScopeData.indexOf(scope), 1);
        props.setSelectedScope(selectedScopeData);
        if (props.data.scopes.every(item => selectedScopeData.includes(item)) && selectedScopeData.every(item => props.data.scopes.includes(item))) {
            const editData = { ...edits };
            editData.scopes = false;
            setEdits(editData)
        }
        else {
            const editData = { ...edits };
            editData.scopes = true;
            console.log(editData)
            setEdits(editData)
        }
    }

    function centerAdd(e) {
        if (e.target.value !== 'null') {
            const index = props.selectedCenters.findIndex((center) => center._id === e.target.value);
            if (index === -1) {
                const data = [...props.selectedCenters];
                const centerData = props.centers.filter((center) => center._id === e.target.value);
                data.push(centerData[0]);
                props.setSelectedCenters(data);
                if (props.data.centerId.every(item => data.includes(item)) && props.data.centerId.every(item => data.includes(item))) {
                    const editData = { ...edits };
                    editData.center = false;
                    setEdits(editData)
                }
                else {
                    const editData = { ...edits };
                    editData.center = true;
                    setEdits(editData)
                }
            }
            else {
                toast.warn('Center already selected!', warningToast)
            }
        }
    }

    function centerDelete(centerData) {
        const selectedCentersData = [...props.selectedCenters];
        selectedCentersData.splice(selectedCentersData.findIndex((center) => center._id === centerData._id), 1);
        props.setSelectedCenters([...selectedCentersData]);
        if (props.data.centerId.every(item => selectedCentersData.includes(item)) && props.data.centerId.every(item => selectedCentersData.includes(item))) {
            const editData = { ...edits };
            editData.center = false;
            setEdits(editData)
        }
        else {
            const editData = { ...edits };
            editData.center = true;
            setEdits(editData)
        }
    }

    function editUser() {
        props.spinner('spinnerWrapper');
        let userName = props.data.name,
            userEmail = props.data.email;
        if (edits.name) {
            userName = memberName
        }
        if (edits.email) {
            userEmail = memberEmail
        }
        const userCenters = [];
        props.selectedCenters.forEach((center) => {
            userCenters.push(center._id)
        })
        const userData = {
            _id: props.data._id,
            name: userName,
            email: userEmail,
            scopes: props.selectedScope,
            centerId: userCenters
        }
        axios.put(`${Config.scheme}://${Config.host}/v1/user/edit`, userData, {
            headers: { Authorization: localStorage.getItem('token') }
        })
            .then((res) => {
                toast.success('User updated successfully!', successToast);
                axios.get(`${Config.scheme}://${Config.host}/v1/user/all`, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                })
                    .then((res) => {
                        props.updateData(res.data.data);
                        props.spinner('invisible');
                        props.closeHandler();
                        setEdits(defaultEdits)
                    })
                    .catch((error) => {
                        if (error.response.status === 401) { navigate('/login') }
                        else {
                            toast.error('An error occured! please refresh the page', errorToast)
                        }
                    })


            })
            .catch((error) => {
                props.spinner('invisible')
                if (error.response.status === 401) { navigate('/login') }
                else {
                    toast.error('An error occured! please refresh the page', errorToast)
                }
            })
    }

    return (
        <div className="modalView" style={{ 'scale': props.modalView[0], 'display': props.modalView[1] }}  >
            <div style={{ 'margin-bottom': props.modal }} className="addMemberModal" >
                <input className="searchBarAllContent" style={{ 'fontSize': '1em', 'marginBottom': '1em' }} type="text" defaultValue={props.data.name} placeholder="Enter Name..." onChange={(e) => { nameEdit(e) }} />
                <input className="searchBarAllContent" style={{ 'fontSize': '1em', 'marginBottom': '1em' }} type="text" defaultValue={props.data.email} placeholder="Enter Email..." onChange={(e) => { emailEdit(e) }} />
                <div className="dropDownSelectGrid" style={{ 'marginBottom': '1em' }} >
                    <select className="customDropDown" onChange={(e) => { scopeAdd(e) }} >
                        <option value="null" >SCOPES</option>
                        {
                            scopes.map((scope) => {
                                return (<option value={scope}>{scope}</option>)
                            })
                        }
                    </select>
                    <div className="selectedItemsGrid" >
                        {
                            props.selectedScope.map((scope) => {
                                return (
                                    <div className="selectedItem" >
                                        <button className="materialButtonSmall" onClick={(e) => { scopeDelete(scope) }} style={{ 'fontSize': '1em' }} >delete</button>
                                        <h1 className="whiteStaticText" style={{ 'fontSize': '80%' }} >{scope}</h1>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="dropDownSelectGrid" style={{ 'marginBottom': '1em' }}  >
                    <select className="customDropDown" onChange={(e) => { centerAdd(e) }} >
                        <option value="null" >CENTER</option>
                        {
                            props.centers.map((center) => {
                                return (<option value={center._id}>{center.name}</option>)
                            })
                        }
                    </select>
                    <div className="selectedItemsGrid" >
                        {
                            props.selectedCenters.map((center) => {
                                return (
                                    <div className="selectedItem" >
                                        <button className="materialButtonSmall" onClick={() => { centerDelete(center) }} style={{ 'fontSize': '1em' }} >delete</button>
                                        <h1 className="whiteStaticText" style={{ 'fontSize': '80%' }} >{center.name}</h1>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="closeFlexHeaderContainer">
                    <button className="purpleButton" onClick={() => { editUser() }} style={{ 'marginRight': '1em' }} disabled={!(edits.center || edits.scopes || edits.email || edits.name)} >Save</button>
                    <button className="purpleButton" onClick={() => {
                        props.closeHandler();
                        setEdits(defaultEdits)
                    }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

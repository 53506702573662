import React, { useState, useEffect } from "react";
import "./batch.css";
import { toast } from "react-toastify";
import { errorToast, warningToast, successToast } from "../../config/toast-config";
import Layout from "../layout/layout";
import axios from "axios";
import { Config } from "../../config/config";

export const Batch = (props) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    event.target.files[0] && setIsFilePicked(true);
  };

  const handleSubmission = () => {
    if (!isFilePicked) return;
    const formData = new FormData();
    formData.append("data", selectedFile);
    axios.post(`${Config.scheme}://${Config.host}/v1/config/batch/bulk/create`, {
      body: formData,
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Success:", result);
        toast.success('Batches Added Successfully', successToast)
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error('An error occured! please refresh the page', errorToast)
      });
  };

  return (
      <div className="batchWrapper">
          <div className="batchUploadDivWrapper">
            <div className="batchUploadDiv">
              <div className="uploadContents">
                <h1 className="whiteStaticHeader">Upload CSV :</h1>
                <input className="batchInputField" type="file" onChange={changeHandler}></input>
              </div>
              <div className="buttonsDiv">
                <button className="purpleButton" onClick={handleSubmission}>Add Batches</button>
                <button className="purpleButton" onClick={props.close}>Close</button>
              </div>
            </div>
          </div>
        </div>
  );
};

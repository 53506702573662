import React, { useState } from "react";
import './manageAccounts.css'
import Layout from "../../components/layout/layout";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { successToast, errorToast, warningToast } from "../../config/toast-config";
import { useNavigate } from "react-router-dom";
import { AddMemberModal } from "../../components/modals/addMemberModal/addMemberModal";
import { Config } from "../../config/config";


export const ManageAccounts = () => {
    const navigate = useNavigate()

    const scopesArray = [
        "CREATE_USERS",
        "CREATE_CENTERS",
        "VIEW_CENTERS",
        "VIEW_USERS",
        "MANAGE_USERS",
        "DELETE_USERS",
        "ADD_VIDEOS",
        "GET_VIDEOS",
        "PUBLISH_VIDEOS",
        "EDIT_VIDEOS",
        "GET_BATCH",
        "CREATE_BATCH"
    ];

    const nullData = {
        _id: '',
        email: '',
        name: '',
        scopes: [],
        centerId: []
    }

    const [allData, setAllData] = useState([]);
    const [data, setData] = useState([]);
    const [spinner, setSpinner] = useState('spinnerWrapper');
    const [scopes, setScopes] = useState(scopesArray);
    const [centers, setCenters] = useState([]);
    
    const [videoModal, setVideoModal] = useState('-50em');
    
    const [deleteModalView, setDeleteModalView] = useState(['0', 'none']);
    const [deleteModalData, setDeleteModalData] = useState({});
    
    const [addModalView, setAddModalView] = useState(['0', 'none']);
    
    const [editModalView, setEditModalView] = useState(['0', 'none']);
    const [editModalData, setEditModalData] = useState({});
    
    const [selectedScope, setSelectedScope] = useState([]);
    const [selectedCenters, setSelectedCenters] = useState([]);
    const [memberName, setMemberName] = useState('');
    const [memberEmail, setMemberEmail] = useState('');

    useEffect(() => {
        axios.get(`${Config.scheme}://${Config.host}/v1/user/all`, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
            .then((res) => {
                setData(res.data.data);
                setAllData(res.data.data);
                setSpinner('invisible');
            })
            .catch((error) => {
                if (error.response.status === 401) { navigate('/login') }
                else {
                    toast.error('An error occured! please refresh the page', errorToast)
                }
            })

        axios.get(`${Config.scheme}://${Config.host}/v1/config/center/all`, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
            .then((res) => {
                setCenters(res.data.data)
                setSpinner('invisible')
            })
            .catch((error) => {
                if (error.response.status === 401) { navigate('/login') }
                else {
                    toast.error('An error occured! please refresh the page', errorToast)
                }
            })

    }, [navigate])

    function userSearch(e) {
        if(e.target.value === '') {
            const rawData = [...allData]
            setData(rawData)
        }
        else {
        const rawData = [...allData]
        const filteredData = rawData.filter(member => String(member.name).toLowerCase().replaceAll(' ', '').includes(String(e.target.value).toLowerCase().replaceAll(' ', '')))
        setData(filteredData);
        }
    }

    function deleteModalCall(data) {
        if (deleteModalView[0] === '0') {
            setDeleteModalData(data)
            setDeleteModalView(['1', 'grid']);
            setVideoModal('0em');
        }
        else {
            setDeleteModalData(nullData)
            setDeleteModalView(['0', 'none']);
            setVideoModal('-50em');
        }
    }

    function deleteUser() {
        setSpinner('spinnerWrapper');
        axios.delete(`${Config.scheme}://${Config.host}/v1/user/delete/${deleteModalData._id}`, {
            headers: { Authorization: localStorage.getItem('token') },
        })
            .then((response) => {
                toast.success('User deleted successfully!', successToast)
                axios.get(`${Config.scheme}://${Config.host}/v1/user/all`, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                })
                    .then((res) => {
                        deleteModalCall()
                        setData(res.data.data)
                        setSpinner('invisible')
                    })
                    .catch((error) => {
                        if (error.response.status === 401) { navigate('/login') }
                        else {
                            toast.error('An error occured! please refresh the page', errorToast)
                        }
                    })
            })
            .catch((error) => {
                if (error.response.status === 401) { navigate('/login') }
                else {
                    toast.error('An unexpected error occured!', errorToast)
                }
            });
    }

    function addMemberModalCall(data) {
        if (addModalView[0] === '0') {
            setAddModalView(['1', 'grid']);
            setVideoModal('0em');
        }
        else {
            setSelectedCenters([]);
            setSelectedScope([]);
            setMemberEmail('');
            setMemberName('');
            setAddModalView(['0', 'none']);
            setVideoModal('-50em');
        }
    }

    function scopeAdd(e) {
        if (e.target.value !== 'null') {
            const index = selectedScope.indexOf(e.target.value);
            if (index === -1) {
                const data = [...selectedScope];
                data.push(e.target.value);
                setSelectedScope(data);
            }
            else {
                toast.warn('Scope already selected!', warningToast)
            }
        }
    }

    function scopeDelete(scope) {
        const selectedScopeData = [...selectedScope];
        selectedScopeData.splice(selectedScopeData.indexOf(scope), 1);
        setSelectedScope(selectedScopeData);
    }

    function centerAdd(e) {
        if (e.target.value !== 'null') {
            const index = selectedCenters.findIndex((center) => center._id === e.target.value);
            if (index === -1) {
                const data = [...selectedCenters];
                const centerData = centers.filter((center) => center._id === e.target.value);
                data.push(centerData[0]);
                setSelectedCenters(data);
                console.log(centerData);
            }
            else {
                toast.warn('Center already selected!', warningToast)
            }
        }
    }

    function centerDelete(centerData) {
        const selectedCentersData = [...selectedCenters];
        selectedCentersData.splice(selectedCentersData.findIndex((center) => center._id === centerData._id), 1);
        setSelectedCenters([...selectedCentersData]);
    }

    function addMember() {
        setSpinner('spinnerWrapper')
        const centerId = [];
        selectedCenters.forEach((center) => {
            centerId.push(center._id)
        })
        const payload = {
            name: memberName,
            email: memberEmail,
            scopes: selectedScope,
            centerId: centerId
        }
        axios.post(`${Config.scheme}://${Config.host}/v1/user/add`, payload, {
            headers: { Authorization: localStorage.getItem('token') }
        })
            .then(() => {
                toast.success('User added successfully!', successToast);
                axios.get(`${Config.scheme}://${Config.host}/v1/user/all`, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                })
                    .then((res) => {
                        setData(res.data.data)
                        setSpinner('invisible')
                        addMemberModalCall();
                    })
                    .catch((error) => {
                        if (error.response.status === 401) { navigate('/login') }
                        else {
                            toast.error('An error occured! please refresh the page', errorToast)
                        }
                    })
            })
            .catch((error) => {
                setSpinner('invisible')
                if (error.response.status === 401) { navigate('/login') }
                else if (error.response.status === 409) { toast.warn('User already exists!', warningToast) }
                else {
                    toast.error('An error occured! please try again later', errorToast)
                }
            })
    }

    function editModalCall(data) {
        if (editModalView[0] === '0') {
            setEditModalData(data);
            setSelectedScope(data.scopes);
            const centersArray = [];
            data.centerId.forEach((centerId) => {
                const filteredData = centers.filter(cent => cent._id === centerId)
                centersArray.push(filteredData[0]);
            })
            setSelectedCenters(centersArray);
            setEditModalView(['1', 'grid']);
            setVideoModal('0em');
        }
        else {
            setEditModalData(nullData);
            setSelectedScope([]);
            setSelectedCenters([]);
            setEditModalView(['0', 'none']);
            setVideoModal('-50em');
        }
    }

    function updateData(data) {
        setAllData(data);
        setData(data);
    }

    return (
        <>
            <div className={spinner}>
                <div className="spinner" />
            </div>

            <div className="modalView" style={{ 'scale': deleteModalView[0], 'display': deleteModalView[1] }}  >
                <div style={{ 'margin-bottom': videoModal }} className="smallModal" >
                    <h1 className="whiteStaticHeader" >Are you sure you want to delete this user? This action can't be undone</h1>
                    <div style={{ 'display': 'flex', 'gap': '2em' }}>
                        <button className="purpleButton" onClick={() => { deleteUser() }} >Yes</button>
                        <button className="purpleButton" onClick={() => { deleteModalCall() }} >No</button>
                    </div>
                </div>
            </div>

            <div className="modalView" style={{ 'scale': addModalView[0], 'display': addModalView[1] }}  >
                <div style={{ 'margin-bottom': videoModal }} className="addMemberModal" >
                    <input className="searchBarAllContent" style={{ 'fontSize': '1em', 'marginBottom': '1em' }} type="text" placeholder="Enter Name..." onChange={(e) => { setMemberName(e.target.value) }} />
                    <input className="searchBarAllContent" style={{ 'fontSize': '1em', 'marginBottom': '1em' }} type="text" placeholder="Enter Email..." onChange={(e) => { setMemberEmail(e.target.value) }} />
                    <div className="dropDownSelectGrid" style={{ 'marginBottom': '1em' }} >
                        <select className="customDropDown" onChange={(e) => { scopeAdd(e) }} >
                            <option value="null" >SCOPES</option>
                            {
                                scopes.map((scope) => {
                                    return (<option value={scope}>{scope}</option>)
                                })
                            }
                        </select>
                        <div className="selectedItemsGrid" >
                            {
                                selectedScope.map((scope) => {
                                    return (
                                        <div className="selectedItem" >
                                            <button className="materialButtonSmall" onClick={() => { scopeDelete(scope) }} style={{ 'fontSize': '1em' }} >delete</button>
                                            <h1 className="whiteStaticText" style={{ 'fontSize': '80%' }} >{scope}</h1>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="dropDownSelectGrid" style={{ 'marginBottom': '1em' }}  >
                        <select className="customDropDown" onChange={(e) => { centerAdd(e) }} >
                            <option value="null" >CENTER</option>
                            {
                                centers.map((center) => {
                                    return (<option value={center._id}>{center.name}</option>)
                                })
                            }
                        </select>
                        <div className="selectedItemsGrid" >
                            {
                                selectedCenters.map((center) => {
                                    return (
                                        <div className="selectedItem" >
                                            <button className="materialButtonSmall" onClick={() => { centerDelete(center) }} style={{ 'fontSize': '1em' }} >delete</button>
                                            <h1 className="whiteStaticText" style={{ 'fontSize': '80%' }} >{center.name}</h1>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="closeFlexHeaderContainer">
                        <button className="purpleButton" onClick={() => {addMember()}} style={{ 'marginRight': '1em' }}>Add</button>
                        <button className="purpleButton" onClick={() => {addMemberModalCall()}}>Cancel</button>
                    </div>
                </div>
            </div>

            <AddMemberModal 
                modal={videoModal} 
                modalView={editModalView} 
                data={editModalData} 
                selectedScope={selectedScope} 
                setSelectedScope={(a) => {setSelectedScope(a)}} 
                selectedCenters={selectedCenters}
                setSelectedCenters={(e) => {setSelectedCenters(e)}}
                closeHandler={() => {editModalCall()}} 
                centers={centers}
                scopes={scopes}
                spinner={(e) => {setSpinner(e)}}
                updateData={(e) => {updateData(e)}}
            />

            <Layout>
                <div className="manageProfiles">

                    <div className="flexHeaderContent">
                        <h1 className="whiteHeader">Manage Accounts</h1>
                        <div className="closeFlexHeaderContent">
                            <input type="text" className="searchBarAllContent" placeholder="Search User..." onChange={(e) => {userSearch(e)}} />
                            <button className="purpleButton" onClick={() => { addMemberModalCall() }} >Add Member</button>
                            <select className="customDropDown" >
                                <option>My Center</option>
                                <option>All Centers</option>
                            </select>
                        </div>
                    </div>

                    <div className="profilesGridContainer" >
                        <div className="profilesGrid">
                            <div className="profileCardsHeader">
                                <h1 className="whiteStaticHeader" >S No.</h1>
                                <h1 className="whiteStaticHeader" >Name</h1>
                                <h1 className="whiteStaticHeader" >Email</h1>
                                <h1 className="whiteStaticHeader" >Scopes</h1>
                                <h1 className="whiteStaticHeader" >Actions</h1>
                            </div>

                            {
                                data.map((user, key) => {
                                    return (
                                        <div className="profileCard">
                                            <h1 className="whiteStaticText" >{key + 1}</h1>
                                            <h1 className="whiteStaticText" >{user.name}</h1>
                                            <h1 className="whiteStaticText" >{user.email}</h1>
                                            <select className="userScopes" >
                                                {user.scopes.map((scope) => {
                                                    return (<option className="whiteStaticText" >{scope}</option>)
                                                })}
                                            </select>
                                            <div className="closeFlexHeaderContent">
                                                <button className="materialButtonSmall" onClick={() => { editModalCall(user) }}>edit</button>
                                                <button className="materialButtonSmall" onClick={() => { deleteModalCall(user) }} >delete</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </Layout>
        </>

    )
}
